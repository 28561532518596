export class Broadcaster {
  private channels: Map<string, BroadcastChannel> = new Map<string, BroadcastChannel>();

  public postMessage<T>(channelName: string, data: T): void {
    let channel = this.channels.get(channelName);
    if (!channel) {
      channel = this.createChannel(channelName);
    }

    channel.postMessage(data);
  }

  public listen<T>(channelName: string, onEvent: (data: T) => void): void {
    let channel = this.channels.get(channelName);
    if (!channel) {
      channel = this.createChannel(channelName);
    }

    // Use onmessage to handle incoming messages
    channel.onmessage = (event: MessageEvent<T>) => {
      onEvent(event.data);
    };
  }

  public closeChannel(channelName: string): boolean {
    let channel = this.channels.get(channelName);
    channel.close();
    return this.channels.delete(channelName);
  }

  public channelExists(channelName: string): boolean {
    return this.channels.has(channelName);
  }

  private createChannel(channelName: string): BroadcastChannel {
    const channel = new BroadcastChannel(channelName);
    this.channels.set(channelName, channel);
    return channel;
  }
}

export default new Broadcaster();